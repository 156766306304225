<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<b-link class="brand-logo">
				<b-img :src="appLogoImage" alt="logo" id="logo" />
				<h2 class="brand-text text-primary ml-1">{{appName}}</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" alt="Login V2" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1">
						Welcome to Capturetool! 👋
					</b-card-title>
					<b-card-text class="mb-2">
					</b-card-text>

					<!-- form -->
					<validation-observer ref="loginValidation">
						<b-form class="auth-login-form mt-2" @submit.prevent>
							<!-- email -->
							<b-form-group label="Email" label-for="login-email">
								<validation-provider #default="{ errors }" name="Email" rules="required|email" :custom-messages="{ required: 'Please enter email', email: 'Please enter valid email' }">
									<b-form-input id="login-email" v-model="userEmail" name="login-email" placeholder="Enter email" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">Password</label>
									<b-link :to="{ name: 'forgot-password' }">
										<small>Forgot Password?</small>
									</b-link>
								</div>
								<validation-provider #default="{ errors }" name="Password" rules="required" :custom-messages="{ required: 'Please enter password'}">
									<b-input-group class="input-group-merge">
										<b-form-input id="login-password" v-model="password" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Enter password" />
										<b-input-group-append is-text>
											<feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- checkbox -->
							<b-form-group>
								<b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
									Remember Me
								</b-form-checkbox>
							</b-form-group>

							<!-- submit buttons -->
							<b-button type="submit" variant="primary" block @click="validationForm">
								Sign in
							</b-button>
						</b-form>
					</validation-observer>
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
	import {
		ValidationProvider,
		ValidationObserver
	} from "vee-validate";
	import {
		BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
	} from "bootstrap-vue";
	import {
		required,
		email
	} from "@validations";
	import {
		togglePasswordVisibility
	} from "@core/mixins/ui/forms";
	import store from "@/store/index";
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { $themeConfig } from '@themeConfig'

	export default {
		components: {
			BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
			ValidationProvider,
			ValidationObserver,
		},
		mixins: [togglePasswordVisibility],
		data() {
			return {
				status: "",
				password: "",
				userEmail: "",
				sideImg: require("@/assets/images/pages/login-v2.svg"),
				required /* validation rulesimport store from '@/store/index' */,
				email,
			};
		},
		computed: {
			passwordToggleIcon() {
				return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
			},
			imgUrl() {
				if (store.state.appConfig.layout.skin === "dark") {
					this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
					return this.sideImg;
				}
				return this.sideImg;
			}
		},
		methods: {
			validationForm() {
				this.$refs.loginValidation.validate().then((success) => {
					if (success) {
						localStorage.setItem('isAuthenticatedUser', 1);
						this.$authAPI.login({
							'login_email': this.userEmail,
							'login_password': this.password,
						}).then((response) => {
							response = response.data;
							if (response.isLogin) {
								window.AuthUserObject = response.user;
								window.AuthSiteObject = response.site;
								this.$root.authUserObject = response.user;
								this.$root.appSiteObject = response.site;
								this.$store.commit('appAuth/APP_LOGIN', {
									'userData': response.user,
									'isLogin': response.isLogin
								});
								this.$store.commit('appModuleFilters/INSERT', response.pageSetting);
								this.$router.push('/summary');
							} else {
								localStorage.setItem('isAuthenticatedUser', 0);
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										text: response.message,
										variant: 'danger',
									},
								})
							}
							return false;
						});
					}
				});
			},
		},
		setup() {
			const { appName, appLogoImage } = $themeConfig.app
			return {
				appName,
				appLogoImage,
			}
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";
</style>